<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2023-01-11 10:25:20
 * @Description: 请您输入文件描述
-->
<template>
  <div class="OverViewEnterprise_wrap animate__animated animate__fadeInLeft">
    <Title :txt="'园区区位'" />
    <div class="OverViewEnterprise_cont_box">
      <div class="OverViewEnterprise_txt_box">
        <p>海星医药健康创新园A区是海新域城市更新集团医药健康产业集群的重要组成部分，是智能化、创新化的高精尖医药健康产业园区。</p>
      </div>
      <div class="OverViewEnterprise_img">
        <img src="~@/assets/images/overViewBottomLeft1.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
export default {
  components: {
    Title
  }
}
</script>
<style lang="less" scoped>
.OverViewEnterprise_wrap {
  margin-top: 10px;
  position: relative;
  height: 355px;
}

.OverViewEnterprise_cont_box {
  position: absolute;
  top: 23px;
  left: 0;
  width: 107%;
}

.OverViewEnterprise_txt_box p {
  text-indent: 2em;
  text-align: justify;
  line-height: 21px;
  font-size: 14px;
}

.OverViewEnterprise_txt_box {
  margin-left: 25px;
  margin-top: 22px;
}

.OverViewEnterprise_img {
  margin-left: 20px;
  margin-top: 18px;

  img {
    -webkit-clip-path: polygon(100% 0%, 100% 87%, 0% 100%, 0% 0%);
  }
}
</style>
<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 16:56:47
 * @Description: 请您输入文件描述
-->
<template>
  <div >
    <TitleRight :txt="'最近七天人员进出情况'" />
    <div id="PeopleAccessEchart" :style="{width: '100%', height: '280px'}">
    </div>
  </div>
</template>
<script>
import TitleRight from '@/components/TitleRight.vue'

export default {
  components: {
    TitleRight
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine () {
      let myChart = this.$echarts.init(document.getElementById('PeopleAccessEchart'))
      myChart.setOption({
        color: ['#eab688', '#9adfdf'],
      //   legend: {
      //   top: '43%',
      //   left: '72.5%',
      //   show: true,
      //   orient: 'vertical',
      //   itemHeight: 8,
      //   itemWidth: 8,
      //   textStyle: {
      //       color: '#fff',
      //       fontSize: '15'
      //   }
      // },
      // 鼠标移入效果
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['员工','访客'],
        top: '3%',
        left: '63.5%',
        itemHeight: 8,
        itemWidth: 8,
        // show: true,
        textStyle: {
          color: '#fff',
          fontSize: '15'
        }
      },
        xAxis: {
          type: 'category',
          data: this.$store.state.overView.peopleDate,

          // 是否留两边间隙
          // boundaryGap: false,
          lineStyle: {
            color: 'rgba(150,215,215,0.8)'
          },
          // 底部线颜色
          axisLine: {
            type: 'dashed',
            color: '#fff'
          }
        },
        yAxis: {
        //   max: this.$store.state.overView.accessMax,
          name: '人/ 次',
          color: '#fff',
          type: 'value',
          splitLine: {
            //网格线
            lineStyle: {
              color: '#424555',
              type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              // width: 4,
            },
            show: true, //隐藏或显示
          },

        },
        series: [
          {
            name: '员工',
            // data: [6,6,6,6,6,6,6],
            data: this.$store.state.overView.staffAccess,
            type: 'line',
            smooth: true,
            // symbol: false,
            symbol: 'rectangle',
            symbolSize: 0,
            itemStyle: {
              normal:{
                  // color:'red',//拐点颜色
                  borderColor:'rgba(231,182,136,1)'  ,//拐点边框颜色
                  borderWidth:1//拐点边框大小
              },
            },
            areaStyle: {   //这是阴影部分
              normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(231,182,136,0.8)' 
              }, {
                  offset: 0.4,
                  color: 'rgba(246,197,154,0.3)'
              }, {
                  offset: 1,
                  color: 'rgba(246,197,154,0.00)'
              }])
              }
            },
          },
          {
            name: '访客',
            // data: [4,4,4,4,4,4,4],
            data: this.$store.state.overView.strangeAccess,
            type: 'line',
            smooth: true,
            // symbol: false,
            symbol: 'rectangle',
            symbolSize: 0,
            itemStyle: {
              normal:{
                  // color:'red',//拐点颜色
                  borderColor:'rgba(150,215,215,1)'  ,//拐点边框颜色
                  borderWidth:1//拐点边框大小
              },
            },
            areaStyle: {   //这是阴影部分
                normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(150,215,215,0.8)' 
                }, {
                    offset: 0.4,
                    color: 'rgba(175,240,240,0.3)'
                }, {
                    offset: 1,
                    color: 'rgba(175,240,240,0.00)'
                }])
                }
            },
          }
        ]
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
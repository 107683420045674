<template>
  <div class="OverViewSurveyEchart_wrap">
    <div class="OverViewSurveyEchart_box">
      <div id="OverViewSurveyEchart" :style="{ width: '100%', height: '155px' }"></div>
    </div>
    <div class="OverViewSurveyEchart_number">
      <span>{{ $store.state.overView.dataInfo.removal }}%</span>
      <p>去化率</p>
    </div>
  </div>
</template>
<script>
// import * as echarts from 'echarts'

import { number } from 'echarts';

export default {
  mounted() {
    this.drawLine();
  },
  data() {
    return {
      hireArea: this.$store.state.overView.dataInfo.hireArea.toFixed(2),
      hiredArea: this.$store.state.overView.dataInfo.hiredArea.toFixed(2)
    };
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById("OverViewSurveyEchart"));
      myChart.setOption({
        tooltip: {
          trigger: "",
        },
        legend: {
          top: "43%",
          left: "72.5%",
          show: true,
          orient: "vertical",
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            color: "#fff",
            fontSize: "15",
          },
        },
        series: [
          // {
          //   name:'舒张压',
          //   type:'line',
          //   stack: '总量',
          //   lineStyle:{
          //     normal:{
          //       color:'#78d528'
          //     }
          //   }
          // },
          {
            // name: 'Access From',
            type: "pie",
            radius: ["68%", "84%"],
            center: ["46%", "50%"],
            avoidLabelOverlap: false,
            label: {
              formatter: `{c}(m²)`,
              // show: true,
              // position: 'center',
              // normal: {
              textStyle: {
                color: "#fff",
              },
              // }
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '40',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              lineStyle: {
                // color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 6,
              length2: 6,
            },
            data: [
              {
                value: this.hireArea,
                name: "未租面积",
                itemStyle: {
                  // radius: ['90%'],
                },
              },
              {
                value: this.hiredArea,
                name: "已租面积",
                itemStyle: {
                  borderColor: "rgba(4,13,38, 0)",
                  borderWidth: 7,
                  // shadowColor: '#4bc9a4',
                  // shadowBlur: '6',
                  // borderWidth: 1
                },
              },
              // { value: 735, name: 'Direct' },
              // { value: 580, name: 'Email' },
              // { value: 484, name: 'Union Ads' },
            ],
            color: ["#2176e4", "#4bc9a4"],
          },
        ],
      });
    },
  },
};
</script>
<style lang="less" scoped>
.OverViewSurveyEchart_wrap {
  position: relative;
}

.OverViewSurveyEchart_number {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 132px;
  top: 0;
  left: 124px;

  p {
    // text-align: center;
    font-size: 16px;
  }

  span {
    text-align: center;
    font-size: 24px;
  }

  .OverViewSurveyEchart_box {
    // transform:skewX(30deg);
  }
}
</style>

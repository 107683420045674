<template>
    <div class="home_left_content">
      <div class="home_left_wrapBg">
      </div>
      <div class="home_left_wrap">
        <OverViewSurvey/>
        <OverViewLocation />
        <OverViewEnterprise />
      </div>
    </div>
</template>
<script>
    // import '@/assets/css/home/home.css'
    import OverViewSurvey from '@/components/overViewLeftCont/OverViewSurvey.vue'
    import OverViewLocation from '@/components/overViewLeftCont/OverViewLocation.vue'
    import OverViewEnterprise from '@/components/overViewLeftCont/OverViewEnterprise.vue'
    export default {
      components: {
        OverViewSurvey,
        OverViewLocation,
        OverViewEnterprise
      }
    }
</script>
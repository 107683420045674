<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 16:57:38
 * @Description: 请您输入文件描述
-->

<template>
  <div>
    <TitleRight :txt="'最近七天车辆进出情况'" />
    <div id="CarAccess" :style="{width: '100%', height: '280px'}">
    </div>
  </div>
</template>
<script>
import TitleRight from '@/components/TitleRight.vue'

export default {
  components: {
    TitleRight
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine () {
      let myChart = this.$echarts.init(document.getElementById('CarAccess'))
      myChart.setOption({
        color: ['#c79c78', '#1289db'],
      //   legend: {
      //   top: '43%',
      //   left: '72.5%',
      //   show: true,
      //   orient: 'vertical',
      //   itemHeight: 8,
      //   itemWidth: 8,
      //   textStyle: {
      //       color: '#fff',
      //       fontSize: '15'
      //   }
      // },
      
      tooltip: {
          trigger: 'axis',
        },
      legend: {
        data: ['员工车','访客车'],
        top: '4%',
        left: '63.5%',
        itemHeight: 8,
        itemWidth: 8,
        // show: true,
        textStyle: {
          color: '#fff',
          fontSize: '15'
        }
      },
        xAxis: {
          type: 'category',
        //   data: ['一', '二', '三', '四', '五', '六', '七'],
          data: this.$store.state.overView.carsDate,

          // 是否留两边间隙
          // boundaryGap: false,
          lineStyle: {
            color: 'rgba(150,215,215,0.8)'
          },
          // 底部线颜色
          axisLine: {
            type: 'dashed',
            color: '#fff'
          }
        },
        yAxis: {
          name: '辆/ 次',
        //   max: 500,
          color: '#fff',
          type: 'value',
          splitLine: {
            //网格线
            lineStyle: {
              color: '#424555',
              type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              // width: 4,
            },
            show: true, //隐藏或显示
          },

        },
        series: [
          {
            name: '访客车',
            // data: [122, 312, 156, 13, 490, 413, 123],
            data: this.$store.state.overView.strangeCars,
            type: 'line',
            smooth: false,
            symbol: 'rectangle',
            symbolSize: 0,
            // symbol: 'rectangle',
            itemStyle: {
              normal:{
                  // color:'red',//拐点颜色
                  borderColor:'rgba(231,182,136,1)'  ,//拐点边框颜色
                  borderWidth:1//拐点边框大小
              },
            },
            areaStyle: {   //这是阴影部分
                normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(231,182,136,0.8)' 
                }, {
                    offset: 0.4,
                    color: 'rgba(231,182,136,0.3)'
                }, {
                    offset: 1,
                    color: 'rgba(231,182,136,0.00)'
                }])
                }
            },
          },
          {
            name: '员工车',
            // data: [67, 311, 56, 311, 211, 451, 213],
            data: this.$store.state.overView.staffCars,
            type: 'line',
            smooth: false,
            symbol: 'rectangle',
            symbolSize: 0,
            itemStyle: {
              normal:{
                  // color:'red',//拐点颜色
                  borderColor:'rgba(22,141,222,1)' ,//拐点边框颜色
                  borderWidth:1//拐点边框大小
              },
            },
            areaStyle: {   //这是阴影部分
                normal: {
                
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(22,141,222,0.8)' 
                }, {
                    offset: 0.4,
                    color: 'rgba(22,141,222,0.3)'
                }, {
                    offset: 1,
                    color: 'rgba(22,141,222,0.00)'
                }])
                }
            },
          }
        ]
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
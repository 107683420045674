<template>
  <div>
    <Title :txt="'园区概况'" />
    <div style="width: 412px;height: 155px;">
      <OverViewSurveyEchart v-if="showOverView" />
    </div>
    <div class="OverViewSurvey_cont">
      <div class="OverViewSurvey_left">
        <div>
          <img src="~@/assets/images/overViewTopLeft1.png" alt="">
        </div>
        <h2>
          <ICountUp :delay="$store.state.overView.ICountUpOption.delay" :endVal="$store.state.overView.dataInfo.allArea"
            :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '2'}" />
        </h2>
        <p class="txt">总建筑面积 <span>m²</span></p>
      </div>
      <div class="OverViewSurvey_right">
        <div class="OverViewSurvey_right_item">
          <BlockLine :color="'#2182ff'" :height="'33'" />
          <div class="txtBlock">
            <h3>
              <ICountUp :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.cars"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}" />
            </h3>
            <p class="txt">车位数量 <span>(个)</span></p>
          </div>
        </div>
        <div class="OverViewSurvey_right_item">
          <BlockLine :color="'#5e69ef'" :height="'33'" />
          <div class="txtBlock">
            <h3>
              <ICountUp :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.buildings"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}" />
            </h3>
            <p class="txt">楼幢数量<span>(幢)</span></p>
          </div>
        </div>
        <div class="OverViewSurvey_right_item">
          <BlockLine :color="'#8865e0'" :height="'33'" />
          <div class="txtBlock">
            <h3>
              <ICountUp :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.plotRatio"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '1'}" />
            </h3>
            <p class="txt">容积率</p>
          </div>
        </div>
        <div class="OverViewSurvey_right_item">
          <BlockLine :color="'#51dbb2'" :height="'33'" />
          <div class="txtBlock">
            <h3>
              <ICountUp :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.buildAre"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '2'}" />
            </h3>
            <p class="txt">占地面积 <span>(m²)</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import BlockLine from '@/components/BlockLine.vue'
import ICountUp from 'vue-countup-v2';


import OverViewSurveyEchart from '@/components/overViewLeftCont/OverViewSurveyEchart.vue'

export default {
  components: {
    ICountUp,
    Title,
    BlockLine,
    OverViewSurveyEchart
  },
  data() {
    return {
      options: {
        // useEasing: true,
        // useGrouping: true,
        separator: '',
        decimal: '.',
        prefix: '',//前缀
        suffix: '',//后缀
        decimalPlaces: '2'//控制小数点
      },
      showOverView: false
    }
  },
  mounted() {
    this.getInitData()
  },
  methods: {
    async getInitData() {
      await this.$store.dispatch('getBeilunWrapData')
      await this.$store.dispatch('getYuanquData')
      setTimeout(() => {
        this.showOverView = true
      }, 1000);
    },
    onReady: function () {
      // const that = this;
      // instance.update(that.endVal + 100);
    }
  }
}
</script>
<style lang="less" scoped>
.OverViewSurvey_cont {
  display: flex;

  .OverViewSurvey_left {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 45%;

    h2 {
      font-size: 32px;
      margin: 5px 0 0;
    }

    // p{
    //   color: #6d85ec;
    // }
    // span{
    //   color: #fff;
    // }
  }

  .OverViewSurvey_right {
    width: 55%;
    display: flex;
    flex-wrap: wrap;

    .OverViewSurvey_right_item {
      display: flex;
      // margin-right: 7px;
      width: 46%;
      margin-bottom: 5px;

    }
  }

  .OverViewSurvey_right .OverViewSurvey_right_item:not(:last-child) {
    margin-right: 7px;
  }

  .OverViewSurvey_right .OverViewSurvey_right_item:last-child {
    width: 47%;
  }
}

.txtBlock {
  margin-left: 6px;
  white-space: nowrap;

  h3 {
    font-size: 26px;
  }

  .txt {
    color: #6d85ec;
    margin-top: -4px;

    span {
      font-size: 12px;
      color: #fff;
    }
  }
}

.BlockLine_wrap {
  margin-left: 5px;
}
</style>
<!--
 * @Author: PC
 * @Date: 2022-04-02 15:58:36
 * @LastEditTime: 2022-08-08 14:38:27
 * @Description: 请您输入文件描述
-->
<template>
	<div>
		<TitleRight :txt="'最近七天食堂消费情况'" />
		<div id="CanteenConsumption" :style="{ width: '100%', height: '280px' }"></div>
	</div>
</template>
<script>
	import TitleRight from '@/components/TitleRight.vue';

	export default {
		components: {
			TitleRight,
		},
		mounted() {
			this.drawLine();
		},
		methods: {
			drawLine() {
				let myChart = this.$echarts.init(document.getElementById('CanteenConsumption'));
				myChart.setOption({
					tooltip: {
						trigger: 'axis',
					},
					// toolbox: {
					//   feature: {
					//     dataView: { show: true, readOnly: false },
					//     magicType: { show: true, type: ['line', 'bar'] },
					//     restore: { show: true },x
					//     saveAsImage: { show: true }
					//   }
					// },
					color: ['#ffc691', '#90b2e2'],
					rotate: 5,
					legend: {
						itemHeight: 10,
						itemWidth: 10,
						top: '3%',
						left: '54.5%',
						data: ['消费人数', '消费金额'],
						textStyle: {
							color: '#fff',
							fontSize: '14',
						},
					},
					xAxis: [
						{
							type: 'category',
							//   data: ['一', '二', '三', '四', '五', '六', '七'],
							data: this.$store.state.overView.canteenDate,
							axisPointer: {
								type: 'shadow',
							},
						},
					],
					yAxis: [
						{
							type: 'value',
							name: '人',
							min: 0,
							//   max: 50,
							// 左边每隔多少值显示
							// interval: 10,
							// 单位
							// axisLabel: {
							//   formatter: '{value} ml'
							// },

							// 左边刻度
							axisLabel: {
								interval: 0,
								rotate: 3, // 角度
							},
							splitLine: {
								//网格线
								lineStyle: {
									color: '#424555',
									type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
									// width: 4,
								},
								show: true, //隐藏或显示
							},
						},
					],
					series: [
						{
							name: '消费人数',
							type: 'bar',
							data: this.$store.state.overView.canteenConsumeNum,
							barWidth: '20',
							itemStyle: {
								//这是阴影部分
								normal: {
									color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
										{
											offset: 0,
											color: 'rgba(151,186,235,0.8)',
										},
										{
											offset: 0.4,
											color: 'rgba(151,186,235,0.8)',
										},
										{
											offset: 1,
											color: 'rgba(151,186,235,0.00)',
										},
									]),
								},
							},
						},
					],
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	#CanteenConsumption {
		transform: skew(0deg, 3deg);
	}
</style>

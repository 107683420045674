<template>
    <div class="over_view_model">
        <!-- <div class="overView_bg"></div> -->
        <!-- <div v-if="$store.state.mapType" class="switch_screen_wrap switch_screen_wrapBg"></div> -->
        <LeftContent v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__delay-0.5s': $store.state.animateDelay, 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <MainContent />
        <RightContent v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__delay-0.5s': $store.state.animateDelay, 'animate__animated': true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
    </div>
</template>
<script>
import LeftContent from '@/components/overView/LeftContent.vue'
import MainContent from '@/components/overView/MainContent.vue'
import RightContent from '@/components/overView/RightContent.vue'
export default {
    name: 'overView',
    components: {
        LeftContent,
        MainContent,
        RightContent,
    },
    data() {
        return {
            screenWidth: document.body.clientWidth,
            scale: document.body.clientWidth / 1920
        }
    },
    mounted() {
        // const that = this
        window.onload = function () {
            adjust()
            window.onresize = function () {
                adjust()
            }
        }
        let adjust = function () {
            let winWidth = document.documentElement.clientWidth
            // // console.log(winWidth)
            let body = document.getElementsByTagName('body')[0]
            // let winHeight = document.body.clientHeight
            // 获取屏幕的宽度 和高度
            let scale = winWidth / 1920
            // // console.log(scale)
            body.style.cssText = 'transform:scale(' + scale + ');'
        }
    },
    watch: {
        /* 监听*/
        screenWidth(val) {
            this.screenWidth = val;
            // console.log("this.screenWidth", this.screenWidth)
        }
    },
    computed: {
        scaleFun: function () {
            var scale = this.scale;
            return `transform:scale(${scale})`
        }
    }
}
</script>
<style>
.over_view_model {
    color: #fff;
    width: 1920px;
    height: 1080px;
    /* position: relative; */
    /* background: url('./../assets/images/bg_icon.png') no-repeat; */
    /* background-size: 100% 87.5%; */
}

.overView_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    display: none;
    /* background: url('./../assets/images/园区总览.png') 100% 100% / cover; */
}
</style>
<template>
    <div class="home_right_content">
        <div class="home_right_wrapBg">
        </div>
        <div class="home_right_wrap">
          <PeopleAccess v-if="showCarAccess" />
          <CarAccess v-if="showCarAccess" />
          <CanteenConsumption v-if="showCarAccess" />
        </div>
    </div>
</template>
<script>
    // import '@/assets/css/home/home.css'
    import PeopleAccess from '@/components/overViewRightCont/PeopleAccess.vue'
    import CarAccess from '@/components/overViewRightCont/CarAccess.vue'
    import CanteenConsumption from '@/components/overViewRightCont/CanteenConsumption.vue'
    export default {
      components: {
        PeopleAccess,
        CarAccess,
        CanteenConsumption
      },
      data () {
          return {
            showCarAccess: false
          }
      },
      mounted () {
          setInterval(() => {
              this.showCarAccess = true
          }, 1000);
      }
    }
</script>
<!--
 * @Author: PC
 * @Date: 2022-07-08 15:39:33
 * @LastEditTime: 2022-08-08 15:36:55
 * @Description: 请您输入文件描述
-->
<template>
  <div class="OverViewLocation_wrap animate__animated animate__fadeInLeft">
    <Title :txt="'企业概况'" />
    <div class="OverViewLocation_list">
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft1.png" alt="">
        </div>
        <div class="txtBlock">
          <h3><ICountUp v-if="$store.state.overView.dataInfo.situation.num"
              :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="$store.state.overView.dataInfo.situation.num ? $store.state.overView.dataInfo.situation.num : '-'"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
              {{$store.state.overView.dataInfo.situation.num ? '' : '-'}}
          </h3>
          <p class="txt">企业数量<span>(家)</span></p>
        </div>
      </div>
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft2.png" alt="">
        </div>
        <div class="txtBlock">
          <h3>
              <!-- {{$store.state.overView.dataInfo.situation.peoples ? $store.state.overView.dataInfo.situation.peoples : '-'}} -->
              <ICountUp
                v-if="$store.state.overView.dataInfo.situation.peoples"
                :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.situation.peoples"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"
              />
              <span v-else>-</span>
            </h3>
          <p class="txt">员工总人数</p>
        </div>
      </div>
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft3.png" alt="">
        </div>
        <div class="txtBlock">
          <h3><ICountUp  v-if="$store.state.overView.dataInfo.situation.financing"
              :delay="$store.state.overView.ICountUpOption.delay"
              :endVal="$store.state.overView.dataInfo.situation.financing"
              :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"/>
              <span v-else>-</span>
          </h3>
          <p class="txt">企业估值<span>(亿元)</span></p>
        </div>
      </div>
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft4.png" alt="">
        </div>
        <div class="txtBlock">
          <h3>
              <!-- {{$store.state.overView.dataInfo.situation.personnel ? $store.state.overView.dataInfo.situation.personnel : '-'}} -->
              <ICountUp
                v-if="$store.state.overView.dataInfo.situation.personnel"
                :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.situation.personnel"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"
              />
              <span v-else>-</span>
            </h3>
          <p class="txt">科技人才数量</p>
        </div>
      </div>
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft5.png" alt="">
        </div>
        <div class="txtBlock">
          <h3>
              <!-- {{$store.state.overView.dataInfo.situation.result ? $store.state.overView.dataInfo.situation.result : '-'}} -->
              <ICountUp
                v-if="$store.state.overView.dataInfo.situation.result"
                :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.situation.result"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"
              />
              <span v-else>-</span>
          </h3>
          <p class="txt">企业创新成果<span>(件)</span></p>
        </div>
      </div>
      <div class="OverViewLocation_item">
        <div>
          <img src="~@/assets/images/overViewCenterLeft6.png" alt="">
        </div>
        <div class="txtBlock">
          <h3>
            <!-- {{$store.state.overView.dataInfo.situation.property ? $store.state.overView.dataInfo.situation.property : '-'}} -->
            <ICountUp
                v-if="$store.state.overView.dataInfo.situation.property"
                :delay="$store.state.overView.ICountUpOption.delay"
                :endVal="$store.state.overView.dataInfo.situation.property"
                :options="{separator: $store.state.overView.ICountUpOption.separator, decimalPlaces: '0'}"
              />
              <span v-else>-</span>
          </h3>
          <p class="txt">知识产权<span>(项)</span></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import ICountUp from 'vue-countup-v2';

export default {
  components: {
    Title,
    ICountUp
  }
}
</script>
<style lang="less" scoped>
  .OverViewLocation_wrap{
    margin-top: 24px;
    position: relative;
    height: 228px;
  }
  .OverViewLocation_list{
    position: absolute;
    top: 24px;
    width: 110%;
    left: 0;
    margin-left: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .OverViewLocation_list .OverViewLocation_item:nth-child(even) {
    // justify-content: flex-end;
  }
  .OverViewLocation_item{
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 46%;
  }
  .txtBlock{
    margin-left: 14px;
    white-space:nowrap;
    h3{
      font-size: 26px;
      line-height: 22px;
    }
    .txt{
      color: #6d85ec;
      margin-top: 4px;
      span{
          font-size: 12px;
          color: #fff;
        }
    }
  }
</style>